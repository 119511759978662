import React from "react";
import Doctor from "./../../images/Female-Asian-Doctor.jpg";

function WhatAreCustomer() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
        <div className="flex flex-wrap items-center justify-center">
          <div className="lg:w-1/3 w-full">
            <div className="w-full flex justify-center">
              <img src={Doctor} alt="" className="lg:w-full w-2/4" />
            </div>
          </div>
          <div className="lg:w-2/3 w-full lg:py-8 py-4 md:px-8 px-6">
            <h6 className="font-normal text-xl mb-4 tracking-2px">
              What our customers are saying
            </h6>
            <h1 className="text-xl font-medium leading-6 tracking-tight mb-4">
              "Patients are getting better service and care from the Voice API
              system, and doctors/nurses can perform more efficiently in their
              jobs. Especially in the emergency room, critical alerts are making
              a very positive difference with both doctors and patients."
            </h1>
            <p className="text-xs font-normal leading-5 tracking-2px uppercase">
              Lin Jingjia
            </p>
            <p className="mb-20 text-xs text-grey-darkest font-normal leading-5 tracking-2px uppercase">
              Senior System Analyst and Programmer, Chi Mei Hospital
            </p>
            <div className="flex flex-wrap">
              <div>
                <a
                  href="/"
                  className="text-xl  p-6 text-white bg-black rounded-xl"
                >
                  Get the story
                </a>
              </div>
              <div>
                <p className="md:ml-10 md:mt-0 mt-28 uppercase tracking-2px font-semibold text-sm border-b-2 border-black">
                  See all customer stories
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatAreCustomer;
